<template>
  <!-- 修改手机号 -->
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <div class="editPhoneBox">
            <!-- 重置密码 -->
            <div class="formBox" v-if="forgetBox">
              <el-form :model="findInfo">
                <el-form-item>
                  <el-input
                    placeholder="请输入原手机号"
                    v-model="findInfo.u_phone"
                  >
                    <i
                      slot="prefix"
                      class="el-input__icon el-icon-mobile-phone"
                    ></i>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    placeholder="请输入新手机号"
                    v-model="findInfo.phone"
                  >
                    <i
                      slot="prefix"
                      class="el-input__icon el-icon-mobile-phone"
                    ></i>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <DragBar></DragBar>
                </el-form-item>
                <el-form-item>
                  <el-input placeholder="请输入验证码" v-model="findInfo.code">
                    <el-button
                      slot="append"
                      type="primary"
                      :disabled="codeBtn"
                      v-prevent-repeat-click
                      @click="gainFindCode"
                      v-if="smsTime > 59"
                    >
                      获取验证码
                    </el-button>
                    <template slot="append" v-if="smsTime < 60">
                      {{ smsTime }}秒后可重发
                    </template>
                  </el-input>
                </el-form-item>
                <el-button
                  type="primary"
                  class="loginBtn"
                  v-prevent-repeat-click
                  @click="resetPhone"
                >
                  提交
                </el-button>
              </el-form>
            </div>
            <!-- 修改成功 -->
            <div class="formBox" v-if="editSuccessBox">
              <div class="successBox">
                <img :src="editSuccess" alt="" />
                <p>
                  手机号修改成功！请牢记，
                  {{ successSeconds }}
                  s后将返回登录页........
                </p>
                <el-button type="primary" class="loginBtn" @click="goLogin">
                  立即返回
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import talentInfo from "@/view/talentPage/talentInfo.vue";
import DragBar from "@/components/dragBar/dragbar.vue";
export default {
  data() {
    return {
      editSuccess: require("@/assets/img/success.png"),
      smsTime: 60,
      successSeconds: 5,
      findInfo: { u_phone: "", phone: "", code: "" },
      forgetBox: true,
      editSuccessBox: false,
    };
  },
  components: {
    talentInfo,
    DragBar,
  },
  computed: {
    ...mapState(["dragState"]),
    codeBtn() {
      return !this.dragState;
    },
  },
  methods: {
    gainFindCode() {
      if (
        this.findInfo.u_phone === this.findInfo.phone &&
        this.findInfo.phone != ""
      ) {
        this.$message.warning("修改的手机号与原手机号一致");
        return;
      }
      if (this.findInfo.phone == "") {
        this.$message.warning("请检查要修改的手机号");
        return;
      }
      // 缺一个原手机号
      let tel = "+86" + this.findInfo.phone;
      this.$http.post("index/login/codesss", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
          this.$message.success("验证码已发送，请您注意查收！");
        }
      });
    },
    resetPhone() {
      if (this.findInfo.u_phone == "") {
        this.$message.warning("请输入原手机号！");
        return;
      }
      if (this.findInfo.phone == "") {
        this.$message.warning("请输入新手机号！");
        return;
      }
      if (this.findInfo.code == "") {
        this.$message.warning("请输入验证码！");
        return;
      }
      if (this.findInfo.u_phone === this.findInfo.phone) {
        this.$message.warning("修改的手机号与原手机号一致");
        return;
      }
      this.$http.post("talent/user/editphone", this.findInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.forgetBox = false;
          this.editSuccessBox = true;
          let _this = this;
          let timer = setInterval(function() {
            _this.successSeconds--;
            if (_this.successSeconds === 1) {
              _this.goLogin();
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    },
    goLogin() {
      localStorage.removeItem("Authorization");
      this.$router.push({ path: "/Login" });
    },
  },
};
</script>
<style scoped>
.talentIndex {
  padding-top: 24px;
}
.editPhoneBox {
  box-shadow: 0px 2px 12px 2px #d0d0d0;
  border-radius: 4px;
  padding: 40px 20px;
  margin-top: 2px;
}
.formBox {
  width: 500px;
  margin: 0 auto;
}
.loginBtn {
  width: 55%;
  display: block;
  margin: 36px auto 20px;
  border-radius: 32px;
  font-size: 16px;
}
.successBox {
  text-align: center;
}
.successBox p {
  color: #2573f1;
}
</style>
